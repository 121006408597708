
























































































































































































































































































































































import Vue, { PropType } from "vue";

import { Business, Role } from "@/types";
import Quantity from "@/components/fragments/QuantityInput.vue";
import employeeStoreModule from "@/store/modules/employee";
import { createNamespacedHelpers } from "vuex";

type ProductType =
  | "product"
  | "service"
  | "service-package"
  | "membership"
  | "voucher";

// type CartItem =
//   | CartProduct
//   | CartService
//   | CartServicePackage
//   | CartMembership
//   | CartVoucher;

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("CART_EMPLOYEES");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  name: "CheckoutItem",
  components: { Quantity },
  props: {
    item: {
      type: Object as PropType<any>,
      required: true,
    },
    product_type: {
      type: String as PropType<ProductType>,
      required: true,
    },
    requests: {
      // type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    apiUrl: VUE_APP_API_URL,
    menu: false,
    qty: 1,
    unitPrice: 0,
    appointmentDate: "",
    appointmentTime: "",
    discountedPrice: 0,
    subTotal: 0,
    staff: "",
    watchThem: false,
  }),
  computed: {
    ...employeeGetters(["employeePage"]),
    role(): Role {
      return this.$store.getters.role;
    },

    hasRoom(): boolean {
      if (
        this.requests &&
        Object.keys(this.requests) &&
        this.product_type == "service"
      ) {
        const key = Object.keys(this.requests).find((x) =>
          x.includes(this.serviceId)
        );
        if (!key) {
          return false;
        } else {
          return this.requests[key].length > 0;
        }
      }
      return false;
    },
    // Business ID computation
    businessId: function (): string {
      return (this.role.business as Business)?._id || "";
    },
    // Service ID computation
    serviceId: function (): string {
      if (this.product_type === "service" && this.item.service) {
        return this.item.service._id;
      }
      return "";
    },
    enforceRoom: function (): boolean {
      if (this.product_type === "service" && this.item.service) {
        return this.item.service.enforceRoom;
      }
      return false;
    },
    // Service IDs for service packages
    serviceIds: function (): string[] {
      if (this.product_type === "service-package" && this.item.services) {
        return this.item.services;
      }
      return [];
    },
  },
  created() {
    this.setValues();
    this.fetchEmployeeList();
  },
  watch: {
    appointmentTime: {
      handler() {
        if (this.watchThem) {
          this.updateItem();
          this.fetchEmployeeList();
        }
      },
      immediate: true,
    },
    appointmentDate: {
      handler() {
        if (this.watchThem) {
          this.updateItem();
          this.fetchEmployeeList();
        }
      },
      immediate: true,
    },
    staff: {
      handler() {
        if (this.watchThem) {
          this.updateItem();
        }
      },
      immediate: true,
    },
    qty: {
      handler() {
        if (this.watchThem) {
          this.updateItem();
        }
      },
      immediate: true,
    },
    unitPrice: {
      handler() {
        if (this.unitPrice > 0 && this.watchThem) {
          this.updateItem();
        }
      },
      immediate: true,
    },
    item: {
      handler() {
        this.setValues();
      },
      deep: true,
    },
  },
  methods: {
    ...employeeActions(["getWithAvailability"]),
    updateItem() {
      switch (this.product_type) {
        case "product":
          this.$store.dispatch("cart/getProduct", {
            productId: this.item.product._id,
            quantity: this.qty,
            unitPrice: this.unitPrice,
          });
          break;
        case "service":
          this.$store.dispatch("cart/getService", {
            serviceId: this.item.id,
            quantity: this.qty,
            unitPrice: this.unitPrice,
            durationInMinutes: this.item.durationInMinutes,
            employeeId: this.staff,
            appointmentDate: this.appointmentDate,
            appointmentTime: this.appointmentTime,
          });
          break;
        case "service-package":
          this.$store.dispatch("cart/getServicePackage", {
            servicePackageId: this.item.servicePackage,
            quantity: this.qty,
            unitPrice: this.unitPrice,
          });
          break;
        case "membership":
          this.$store.dispatch("cart/getMembership", {
            membershipId: this.item.id,
            quantity: this.qty,
            unitPrice: this.unitPrice,
            type: this.item.type,
            period: this.item.period,
            duration: this.item.duration,
          });
          break;
        case "voucher":
          this.$store.dispatch("cart/getVoucher", {
            voucherId: this.item.id,
            quantity: this.qty,
            unitPrice: this.unitPrice,
            maxUsageCount: this.item.maxUsageCount,
          });
          break;
      }
    },
    // New method to handle date and time changes and emit service request
    handleDateTimeChange() {
      if (!this.appointmentDate || !this.appointmentTime) return;

      if (this.product_type === "service") {
        // Create date string in ISO format
        const startDateIso = `${this.appointmentDate}T${this.appointmentTime}:00`;

        const serviceRequest = {
          serviceId: this.serviceId,
          businessId: this.businessId,
          startDateIso: startDateIso,
          duration: this.item.durationInMinutes,
        };

        // Emit the service request for availability checking
        console.log("emiting");
        console.log(serviceRequest);
        this.$emit("serviceRequest", serviceRequest);
      }
      // else if (this.product_type === "service-package") {
      //   // For service packages, emit multiple service requests
      //   const startDateIso = `${this.appointmentDate}T${this.appointmentTime}:00`;

      //   const serviceRequests = this.serviceIds.map((serviceId) => ({
      //     serviceId,
      //     businessId: this.businessId,
      //     startDateIso: startDateIso,
      //     duration: this.item.durationInMinutes,
      //   }));

      //   // Emit the service requests array for availability checking
      //   this.$emit("service-requests", serviceRequests);
      // }
    },
    getImageUrl(image: any) {
      if (image) {
        return `${this.apiUrl}/v1/file/${image.filename}`;
      }
      return "../../assets/placeholder.png";
    },
    fetchEmployeeList() {
      if (!this.appointmentDate || !this.appointmentTime || this.item.employee)
        return;
      const bid = this.businessId;
      const date = `${this.appointmentDate} ${this.appointmentTime}`;
      const duration = this.item.durationInMinutes;
      let params = `?businessId=${bid}&startDate=${date}`;
      if (duration) {
        params += `&durationInMinutes=${duration}`;
      }
      if (this.product_type === "service" && this.serviceId) {
        params += `&serviceIds=${this.serviceId}`;
      }
      this.getWithAvailability(params);
    },
    getRules(field: string) {
      return [(v: string) => !!v || `${field} is required`];
    },
    setValues() {
      if (this.qty != this.item.quantity) this.qty = this.item.quantity;
      if (this.unitPrice != this.item.unitPrice)
        this.unitPrice = this.item.unitPrice;
      if (this.appointmentDate != this.item.appointmentDate)
        this.appointmentDate = this.item.appointmentDate;
      if (this.appointmentTime != this.item.appointmentTime)
        this.appointmentTime = this.item.appointmentTime;
      if (this.staff != this.item.staff) this.staff = this.item.staff;
      if (this.discountedPrice != this.item.discountedPrice)
        this.discountedPrice = this.item.discountedPrice;
      if (this.subTotal != this.item.subTotal)
        this.subTotal = this.item.subTotal;
      setInterval(() => {
        this.watchThem = true;
      }, 1000);
    },
    removeItem() {
      this.$store.dispatch("cart/removeCartItem", {
        item: this.item,
        itemType: this.product_type,
      });

      // Emit the serviceId when a service item is removed
      if (this.product_type === "service" && this.serviceId) {
        this.$emit("service-removed", this.serviceId);
      }
      // Emit multiple serviceIds when a service package is removed
      else if (
        this.product_type === "service-package" &&
        this.serviceIds.length
      ) {
        this.$emit("services-removed", this.serviceIds);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CART_EMPLOYEES")) {
      this.$store.registerModule("CART_EMPLOYEES", employeeStoreModule);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("CART_EMPLOYEES");
  },
});
