






































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import CheckoutItem from "@/components/checkout/CheckoutItem.vue";
import { Business, Client, Order, Role, User } from "@/types";
import employeeStoreModule from "@/store/modules/employee";
import CheckoutStep from "@/components/checkout/CheckoutStepper.vue";
import { getQrCode, validatePhone } from "@/util/payment";
import OrderStoreModule from "@/store/modules/order";
import PaymentStoreModule from "@/store/modules/payment";
import MembershipModule from "@/store/modules/clientMembership";
// import paystackStoreModule from "@/store/modules/paystack";
import { validateShippingCart } from "@/util/shipping";
import ClientModule from "@/store/modules/client";
import { PHONE_REGEX_KE } from "@/util/constants";
import locationStoreModule from "@/store/modules/location";
import voucherStoreModule from "@/store/modules/voucher";

const stripePubKey = process.env.VUE_APP_STRIPE_PUB_KEY;

const { mapActions: orderActions } = createNamespacedHelpers("ORDER_CHECKOUT");
const { mapActions: paymentActions } =
  createNamespacedHelpers("ORDER_PAYMENTS");
const { mapActions: membershipActions, mapGetters: membershipGetters } =
  createNamespacedHelpers("_Membership");
const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("_Client");

// const { mapActions: paystackActions } = createNamespacedHelpers("PAYSTACK_");

const { mapActions: locationActions, mapGetters: locationGetters } =
  createNamespacedHelpers("LOCATION_LIST");

const { mapActions: voucherActions } = createNamespacedHelpers("CHECK_VOUCHER");

const payment_items = [
  { title: "Shipping Details", value: "shipping" },
  { title: "Apply Voucher", value: "voucher" },
  { title: "Apply Membership", value: "membership" },
  { title: "Pay By Cash", value: "cash" },
  { title: "Pay By Mpesa", value: "mpesa" },
  { title: "Pay By Service Package", value: "service-package" },
];

const PAYSTACK_PUBLIC_KEY = process.env.VUE_APP_PAYSTACK_PUBLIC_KEY;
//
export default Vue.extend<any, any, any, any>({
  name: "CheckoutPage",
  components: { CheckoutItem, CheckoutStep },
  data: () => ({
    isLoading: false,
    loadingMessage: "Processing Payment...",
    addToCart: false,
    voucherDialog: false,
    membershipDialog: false,
    cashDialog: false,
    cardDialog: false,
    qrDialog: false,
    mpesaDialog: false,
    shippingDialog: false,
    addressDialog: false,
    giftCode: "",
    paymentItems: payment_items,
    stripePk: stripePubKey,
    qr: {
      step: 1,
      timer: 300,
      creatingOrder: false,
      fetchingOrder: false,
      interval: null,
    },
    cash: {
      cash_amount: 0,
      cash_balance: 0,
      total_amount: 0,
    },
    card: {
      total_amount: 0,
      card_number: undefined,
      exp_date: "",
      cvc: undefined,
      client_name: "",
      client_email: "",
      client_phone: "",
      subscription: false,
    },
    mpesa: {
      total_amount: 0,
      phone: "",
    },
    shippingAddress: {
      country: "",
      city: "",
      phone: "",
      addressline1: "",
      addressline2: "",
      zip_code: "",
    },
    shipping: {
      country: "",
      city: "",
      address: "",
      phone: "",
      notes: "",
    },
    orderID: "",
    qrcode: "",
    //clientSubscriptions: [],
    selectedSubscription: null as any,
    // subscriptionDiscount: 0,
    shippingMethods: [] as any[],
    selectedShippingMethod: undefined,
    shippingCost: 0,
    selectedAddress: undefined as any,
    addressChoice: undefined as any,
    selectedQRPaymentMethod: undefined as any,
    mpesaPhoneRules: [
      (v: string) => !!v || "Phone Number is required",
      (v: string) => PHONE_REGEX_KE.test(v) || "Invalid phone number",
    ],
    order: undefined as undefined | Order,
    pollInterval: undefined as any,
    pollTimeout: undefined as any,
    paystackDialog: false,
    paystack: {
      key: PAYSTACK_PUBLIC_KEY,
      amount: 0,
      orderId: "",
    },
    authUrl: "",
    useShopAddrr: false,
    voucherAmount: 0,
    cardDeposit: false,
    servicePackageDialog: false,
    servicePackageCode: "",
    availabilities: [] as any[],
  }),
  computed: {
    ...clientGetters(["getClient"]),
    ...membershipGetters(["clientMembershipPage"]),
    ...locationGetters(["locationPage"]),
    products: function (): CartProduct[] {
      return this.$store.getters["cart/products"];
    },
    services: function (): CartService[] {
      return this.$store.getters["cart/services"];
    },
    memberships: function (): CartMembership[] {
      return this.$store.getters["cart/memberships"];
    },
    vouchers: function (): CartVoucher[] {
      return this.$store.getters["cart/vouchers"];
    },
    client: function (): Client {
      return this.$store.getters["cart/client"];
    },
    packages: function (): CartServicePackage[] {
      return this.$store.getters["cart/servicePackages"];
    },
    discountAmount() {
      return this.$store.getters["cart/getDiscount"];
    },
    addresses: function (): any[] {
      if (this.client) {
        const user = (this.client as any).user;
        if (user) {
          return user?.addresses;
        } else {
          return [];
        }
      }
      return [];
    },
    cartQty: function (): number {
      return this.$store.getters["cart/quantity"];
    },
    cartTotal: function (): number {
      return this.$store.getters["cart/total"];
    },
    grandTotal: function (): number {
      return this.cartTotal + this.shippingCost; // - this.subscriptionDiscount
    },
    payableAmount(): number {
      return this.$store.getters["cart/payableAmount"](this.shippingCost);
    },
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
    employee(): User {
      return this.role.user as User;
    },
    shippingCheck(): any {
      return [this.products, this.cartQty];
    },
    user(): User {
      return this.$store.getters.user;
    },
    deposit(): number {
      return this.$store.getters["cart/depositAfterDiscount"];
    },
    // cartTotalWithDeposit(): number {
    //   return this.$store.getters["cart/totalWithDeposit"];
    // },
    roomAvailability(): any {
      return this.$store.getters["cart/roomAvailability"];
    },
    payOnlyDeposit: {
      get() {
        return this.$store.getters["cart/payOnlyDeposit"];
      },
      set(value: boolean) {
        this.$store.commit("cart/setPayOnlyDeposit", value);
      },
    },
  },
  watch: {
    cash: {
      handler() {
        const { cash_amount, cash_balance } = this.cash;
        const total =
          this.payableAmount > 0 ? this.payableAmount : this.cartTotal;
        this.cash.cash_balance = total > cash_amount ? 0 : cash_amount - total;
        this.cash.total_amount = cash_amount - cash_balance;
      },
      deep: true,
      immediate: true,
    },
    payOnlyDeposit: {
      handler() {
        const { cash_amount, cash_balance } = this.cash;
        const total =
          this.payableAmount > 0 ? this.payableAmount : this.cartTotal;
        this.cash.cash_balance = total > cash_amount ? 0 : cash_amount - total;
        this.cash.total_amount = cash_amount - cash_balance;
      },
      immediate: true,
    },
    addressChoice() {
      this.fetchShippingMethods();
    },
    shippingCheck() {
      this.fetchShippingMethods();
    },
    selectedShippingMethod(value: any) {
      if (value) {
        this.shippingCost = value.rate;
      } else {
        this.shippingCost = 0;
      }
    },
    selectedAddress(value: any) {
      if (value) {
        this.shippingAddress = { ...value };
      } else {
        this.shippingAddress = {
          country: "",
          city: "",
          addressline1: "",
          addressline2: "",
          zip_code: "",
          phone: "",
        };
      }
    },
    products() {
      if (this.products.length > 0) {
        this.fetchShippingMethods();
      }
    },
    useShopAddrr() {
      if (this.useShopAddrr) {
        this.setAddress();
      }
    },
  },
  created() {
    if (this.role) {
      const bid = (this.role.business as Business)._id;
      this.fetchLocationList(`?businessId=${bid}&limit=10000`);
    }
  },
  methods: {
    ...orderActions(["createOrder", "fetchOrderList", "retryOrderPayment"]),
    ...paymentActions(["createPayment"]),
    ...membershipActions([
      "createMembershipSession",
      "verifyMembershipApply",
      "fetchClientMembershipList",
    ]),
    ...clientActions(["updateUserAddress", "fetchClient"]),
    // ...paystackActions(["initTransaction"]),
    ...locationActions(["fetchLocationList"]),
    ...voucherActions(["validateVoucher"]),
    checkAvailability(serviceRequest) {
      const index = this.availabilities.findIndex(
        (item) => item.serviceId === serviceRequest.serviceId
      );
      console.log(index);

      if (index !== -1) {
        // Replace the item at the found index.
        // For example, you can update it with the new serviceRequest object:
        this.availabilities[index] = serviceRequest;

        // Or if you only want to update specific properties:
        // this.availabilities[index] = { ...this.availabilities[index], someProperty: serviceRequest.someProperty };
      } else {
        this.availabilities.push(serviceRequest);
      }

      if (this.availabilities.length) {
        this.$store
          .dispatch("cart/fetchRoomAvailability", this.availabilities)
          .then(() => {
            console.log(this.roomAvailability);
          });
      }
    },
    handleServiceRemoval(serviceId) {
      console.log("Removing service with id:", serviceId);

      // Find the index of the service to remove
      const index = this.availabilities.findIndex(
        (item) => item.serviceId === serviceId
      );

      if (index !== -1) {
        // Remove the service from the array
        this.availabilities.splice(index, 1);
        console.log(`Service with id ${serviceId} has been removed.`);
      } else {
        console.log(`Service with id ${serviceId} not found.`);
      }

      // Update room availability if there are any remaining services
      if (this.availabilities.length) {
        this.$store
          .dispatch("cart/fetchRoomAvailability", this.availabilities)
          .then(() => {
            console.log(this.roomAvailability);
          });
      }
    },
    fetchShippingMethods() {
      if (this.role && this.products.length > 0) {
        validateShippingCart({
          business: this.vendor._id,
          cart_total: this.grandTotal - this.shippingCost,
          products: this.products,
          address: this.addressChoice,
        }).then((result) => {
          this.shippingMethods = result.data.methods ?? [];
          this.selectedShippingMethod = this.shippingMethods[0];
        });
      }
    },
    updateShippingAddress() {
      this.shippingDialog = false;
    },
    addUserShippingAddress() {
      //updating user-address
      if (this.client) {
        const userId = (this.client as any).user._id;
        this.updateUserAddress({
          addresses: [{ ...this.shippingAddress }],
          userId,
        }).then((response) => {
          this.fetchClient(`?clientId=${this.client._id}`).then(
            (updatedClient: any) => {
              this.$store.commit("cart/SET_CLIENT", updatedClient);
            }
          );
          this.addressDialog = false;
          this.selectedAddress = undefined;
        });
      }
    },
    closedPaymentModal(response: any) {
      console.log("payment modal is closed");
      console.log(response);
    },
    generateReference() {
      let date = new Date();
      return date.getTime().toString();
    },
    selectSubscription(item) {
      if (this.selectedSubscription?._id != item._id)
        this.selectedSubscription = item;
      else this.selectedSubscription = null;
    },
    applyMembershipNew() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Membership not applied!",
          text: "Please provide client Details",
        });
        return;
      }
      if (!this.selectedSubscription) {
        this.$swal.fire({
          icon: "error",
          title: "Membership not applied!",
          text: "Please select a subcription",
        });
        return;
      }
      this.createMembershipSession({
        clientMembershipId: this.selectedSubscription._id,
        quantity: this.selectedSubscription.quantity,
        serviceIds: this.services.map((item) => item.service._id),
        productIds: this.products.map((item) => item.product._id),
      }).then((session) => {
        if (session) {
          this.membershipDialog = false;

          const orderDetails = {
            notes: "Pay by membership",
            payment: {
              method: "membership", //card|m-pesa|cash|paylater
              source: session._id,
              // amount: this.payableAmount,
              payOnlyDeposit: this.payOnlyDeposit,
              currency: "KES",
            },
          };
          this.isLoading = true;
          this.loadingMessage = "Creating Order...";
          this.saveOrder({ ...orderDetails })
            .then(({ order }) => {
              if (order) {
                this.processOrderPayment((order as Order)._id);
              }
            })
            .catch((e) => {
              this.loadingMessage = "Order Not Created";
              this.$swal.fire({
                icon: "error",
                title: "Order Not Created!",
                text: (e as Error).message || "Error while processing Order",
              });
              this.isLoading = false;
            });
        }
      });
    },
    applyMembership() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Membership not applied!",
          text: "Please provide client Details",
        });
        return;
      }

      this.verifyMembershipApply({
        clientId: this.client._id,
        membershipId: this.memberships[0].id,
        quantity: this.memberships[0].quantity,
      }).then((res) => {
        const { success, message } = res;
        console.log(success);
        if (
          this.selectedSubscription &&
          this.memberships.length > 0 &&
          success
        ) {
          // this.subscriptionDiscount = this.memberships[0].subTotal || 0;
          const { membership, quantity } = this.memberships[0];
          this.createMembershipSession({
            businessId: this.vendor._id,
            clientId: this.client._id,
            membershipId: membership._id,
            quantity,
          });
        } else {
          // this.subscriptionDiscount = 0;
        }
        this.membershipDialog = false;
      });
    },
    openDialog(type: string) {
      if (type == "shipping") {
        if (this.client) {
          const { phone } = this.client;
          this.shipping.phone = phone;
        }
        this.shippingDialog = true;
      }

      if (type == "voucher") {
        this.voucherDialog = true;
      }

      if (type == "membership") {
        if (!this.client) {
          this.$swal.fire({
            icon: "error",
            title: "Process not initialized!",
            text: "Please provide client Details",
          });
          return;
        }
        const params = `?clientId=${this.client._id}&businessId=${this.vendor._id}&paymentStatus=`;
        this.fetchClientMembershipList(params).then((r) => {
          this.membershipDialog = true;
        });
      }

      if (type == "cash") {
        this.cashDialog = true;
        this.cash = {
          cash_amount: 0,
          cash_balance: 0,
          total_amount: 0,
        };
      }

      if (type == "card") {
        if (this.client) {
          this.card.client_name = this.client.fullName;
          this.card.client_email = this.client.email;
          this.card.client_phone = this.client.phone;
        }
        this.card.total_amount = this.grandTotal - this.discountAmount;
        this.cardDialog = true;
      }

      if (type == "mpesa") {
        this.mpesa.total_amount = this.grandTotal - this.discountAmount;
        this.mpesaDialog = true;
      }
      if (type == "qr") {
        if (!this.client) {
          this.$swal.fire({
            icon: "error",
            title: "Payment Not Initialized",
            text: "Please provide client Details",
          });
          return;
        }

        if (this.services.length == 0 && this.products.length == 0) {
          this.$swal.fire({
            icon: "error",
            title: "Cart error",
            text: "Please add items to cart",
          });
          return;
        }
        this.qrDialog = true;
      }
      if (type == "service-package") {
        this.servicePackageDialog = true;
      }
    },
    formatPhone(phone: string) {
      const codedPlus =
        /^(?:\+254)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;
      const zero = /^(?:0)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;
      const coded =
        /^(?:254)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;

      if (phone.match(codedPlus)) {
        const newPhoneNumber = phone.substring(1);
        return newPhoneNumber;
      }

      if (phone.match(coded)) {
        return phone;
      }

      if (phone.match(zero)) {
        const newPhoneNumber = phone.substring(1);
        const newPhone = `254${newPhoneNumber}`;
        return newPhone;
      }
      return phone;
    },
    async generateQRCode() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });

        this.resetQrPayment();
        return;
      }

      if (this.services.length == 0 && this.products.length == 0) {
        this.$swal.fire({
          icon: "error",
          title: "Cart error",
          text: "Please add items to cart",
        });
        this.resetQrPayment();
        return;
      }
      this.qr.creatingOrder = true;

      if (this.orderID == "") {
        try {
          const result: any = await this.saveOrder({
            notes: "Pay by QR",
            payment: {
              method: this.selectedQRPaymentMethod ?? "card", //card|m-pesa|cash|paylater
              source: "ahidi",
              // amount: this.grandTotal,
              payOnlyDeposit: this.payOnlyDeposit,
              currency: "KES",
            },
          });

          if (!result?.order) {
            this.$swal.fire({
              icon: "error",
              title: "Payment Not Initialized",
              text: "Please Create an Order",
            });
            this.resetQrPayment();
            return;
          } else {
            this.orderID = result.order?._id;
          }
        } catch (error) {
          this.resetQrPayment();

          return;
        }
      }

      ///
      const fcm_token = localStorage.getItem("fcm-token");
      const payload = {
        currency: "KES",
        country: "kenya",
        vendor_id: this.$store.getters.user._id,
        client_id: this.client._id,
        order_id: this.orderID,
        fcm_token,
      };
      try {
        const { data } = await getQrCode(payload);
        this.qrcode = data.qrcode;

        this.qr.step = 2;
        this.qr.creatingOrder = false;

        this.qrTimer();
      } catch (error: any) {
        this.resetQrPayment();
        this.$store.dispatch(
          "setToast",
          {
            title: "Request failed!",
            type: "error",
            text: error.response?.data?.error?.message,
          },
          { root: true }
        );
      }

      try {
        this.qr.step = 3;
        this.qr.fetchingOrder = true;

        (this.qr.interval as any) = setInterval(async () => {
          const { order }: any = await this.fetchOrder(this.orderID);

          console.log(order);

          if (order.paymentStatus == "completed") {
            this.resetQrPayment();

            this.$router.push(`/order/${this.orderID}`);
          }
        }, 3000);
      } catch (error: any) {
        this.resetQrPayment();
        this.$store.dispatch(
          "setToast",
          {
            title: "Request failed!",
            type: "error",
            text: error.response?.data?.error?.message,
          },
          { root: true }
        );
      }
    },
    async doMpesaPayment() {
      const valid = (
        this.$refs.mpesaForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const { phone } = this.mpesa;

      if (!validatePhone(phone)) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide a valid phone Number!",
        });
        return;
      }

      const orderDetails = {
        notes: "Pay by Mpesa",
        payment: {
          method: "m-pesa", //card|m-pesa|cash|paylater
          source: phone,
          // amount: this.payableAmount,
          payOnlyDeposit: this.payOnlyDeposit,
          currency: "KES",
        },
      };
      this.isLoading = true;
      this.loadingMessage = "Creating Order...";
      this.saveOrder({ ...orderDetails })
        .then(({ order }) => {
          if (order) {
            this.processOrderPayment((order as Order)._id);
          }
        })
        .catch((e) => {
          this.loadingMessage = "Order Not Created";
          this.$swal.fire({
            icon: "error",
            title: "Order Not Created!",
            text: (e as Error).message || "Error while processing Order",
          });
          this.isLoading = false;
        });
    },

    async retryPayment() {
      if (this.order) {
        const orderId = (this.order as Order)._id;
        const cost = this.order.cost;
        const { method, currency, amount, source } = this.order.payment;
        try {
          this.order = undefined;
          await this.retryOrderPayment({
            id: orderId,
            payment: {
              method: method,
              currency: currency || "KES",
              // amount: cost || amount,
              payOnlyDeposit: this.payOnlyDeposit,
              source: source || "",
            },
          });
          this.processOrderPayment(orderId);
        } catch (e) {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: "Order Payment Failed!",
            text: (e as Error).message || "Error while processing Order",
          });
        }
      }
    },
    async applyVoucher() {
      if (!this.client) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide client Details",
        });
        return;
      }
      if (this.giftCode != "") {
        const payload = {
          voucherCode: this.giftCode,
          products: this.products.map((prod) => ({
            id: prod.product._id,
            cost: prod.quantity * (prod.unitPrice - prod.discount),
          })),
          services: this.services.map(
            ({ id, quantity, depositAfterDiscount, unitPrice, discount }) => ({
              id,
              cost:
                quantity *
                (this.payOnlyDeposit
                  ? depositAfterDiscount
                  : unitPrice - discount),
            })
          ),
        };

        const data = await this.validateVoucher(payload);

        if (data) {
          const { clientVoucher, applyAmount } = data;

          if (this.client._id !== clientVoucher.client._id) {
            this.$swal.fire({
              icon: "error",
              title: "Voucher Error",
              text: "Invalid Client",
            });
            return;
          }

          this.voucherAmount = applyAmount;
          this.voucherDialog = false;

          const orderDetails = {
            appliedVouchers: [
              {
                clientVoucher: clientVoucher._id,
                appliedAmount: this.voucherAmount,
              },
            ],
            notes: "Pay by Voucher",
            payment: {
              method: "voucher", //card|m-pesa|cash|paylater
              source: this.giftCode,
              // amount: this.payableAmount,
              payOnlyDeposit: this.payOnlyDeposit,
              currency: "KES",
            },
          };
          this.isLoading = true;
          this.loadingMessage = "Creating Order...";
          this.saveOrder({ ...orderDetails })
            .then(({ order }) => {
              if (order) {
                this.processOrderPayment((order as Order)._id);
              }
            })
            .catch((e) => {
              this.loadingMessage = "Order Not Created";
              this.$swal.fire({
                icon: "error",
                title: "Order Not Created!",
                text: (e as Error).message || "Error while processing Order",
              });
              this.isLoading = false;
            });
        }
      }
    },
    async doCashPayment() {
      const orderDetails = {
        notes: "Pay by Cash",
        payment: {
          method: "cash", //card|m-pesa|cash|paylater
          source: this.client.phone,
          // amount: this.payableAmount,
          payOnlyDeposit: this.payOnlyDeposit,
          currency: "KES",
        },
      };
      this.isLoading = true;
      this.loadingMessage = "Processing order.....";
      this.saveOrder({ ...orderDetails })
        .then(({ order }) => {
          if (order) {
            const _order = order as Order;
            this.processOrderPayment(_order._id);
          }
        })
        .catch((e) => {
          this.$swal.fire({
            icon: "error",
            title: "Cash Payment Error",
            text: (e as Error).message || "An Error Occurred!",
          });
        });
    },
    processOrderPayment(orderId: string): void {
      this.loadingMessage = "Processing Payment...";
      this.pollInterval = setInterval(async () => {
        const updatedOrder: any = await this.fetchOrder(orderId);
        if (updatedOrder) {
          if (
            updatedOrder.paymentStatus == "completed" ||
            updatedOrder.payment.amount - updatedOrder.discountAmount >=
              this.payableAmount
          ) {
            const text = "Order Payment received Successfully";
            this.$swal.fire({
              icon: "success",
              title: "Order Paid",
              text,
            });

            this.isLoading = false;
            this.cashDialog = false;
            this.cardDialog = false;
            this.mpesaDialog = false;
            this.resetForms();
            this.$store.dispatch("cart/deleteCart");
            this.$router.push(`/order/${orderId}`);
            clearInterval(this.pollInterval);
          }
        }
      }, 5000);

      this.pollTimeout = setTimeout(async () => {
        console.log("--Timeout--");
        clearInterval(this.pollInterval);
        const updatedOrder = await this.fetchOrder(orderId);
        this.order = updatedOrder as Order;
      }, 60000);
    },
    saveOrder(data: any): Promise<{ order: Order; checkoutUrl: string }> {
      return new Promise((resolve, reject) => {
        if (!this.client) {
          this.$swal.fire({
            icon: "error",
            title: "Payment Not Initialized",
            text: "Please provide client Details",
          });
          return reject(new Error("Order Not Created"));
        }
        let shipping: any = null;

        if (this.products.length > 0) {
          if (!this.selectedShippingMethod) {
            this.$swal.fire({
              icon: "error",
              title: "Please select a shipping Method",
              text: "Please select a shipping Method",
            });
            return reject(new Error("Order Not Created"));
          }
          const {
            country = "Kenya",
            city = "n/a",
            addressline1 = "n/a",
            phone = "n/a",
          } = (this.addressChoice as any) || {};

          shipping = {
            contact: phone,
            delivery_note: "notes",
            shipping_method: (this.selectedShippingMethod as any)
              .shipping_method._id,
            country,
            city,
            address: addressline1,
            shipping_cost: this.shippingCost,
          };
        }
        this.$store
          .dispatch("cart/checkout", {
            storePickUp: this.useShopAddrr,
            payment: data.payment,
            notes: data.notes,
            shipping,
          })
          .then((result) => {
            if (result) resolve(result);
            else reject(new Error("Order Not Created"));
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchOrder(orderId: any) {
      return new Promise((resolve, reject) => {
        if (this.role) {
          const businessId = (this.role.business as Business)._id;
          this.fetchOrderList(`?orderId=${orderId}&businessId=${businessId}`)
            .then((response) => {
              const { order } = response.data;
              resolve(order);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error("Order Not Initialized"));
        }
      });
    },

    savePayment(data: any) {
      return this.createPayment(data);
    },
    resetForms() {
      this.mpesa.phone = "";
      this.cash = {
        cash_amount: 0,
        cash_balance: 0,
        total_amount: 0,
      };
      this.card = {
        total_amount: 0,
        card_number: undefined,
        cvc: undefined,
        exp_date: "",
        client_name: "",
        client_email: "",
        client_phone: "",
        subscription: false,
      };
      this.giftCode = "";
      this.selectedShippingMethod = undefined;
      this.shipping = {
        country: "",
        city: "",
        address: "",
        phone: "",
        notes: "",
      };
      this.$store.dispatch("cart/deleteCart");
    },

    resetQrPayment() {
      this.qr.step = 1;
      this.qrDialog = false;
      this.qr.creatingOrder = false;
      this.qr.fetchingOrder = false;
      this.qrcode = "";
      this.orderID = "";
      this.qr.timer = 300;

      clearInterval(this.qr.interval as any);
    },

    regenerateQRCode() {
      this.qr.step = 1;
      this.qr.creatingOrder = false;
      this.qrcode = "";
      this.qr.timer = 300;

      this.generateQRCode();
    },

    qrTimer() {
      if (this.qr.timer > 0) {
        setTimeout(() => {
          this.qr.timer -= 1;
          this.qrTimer();
        }, 1000);
      }
    },
    payByPaystack() {
      if (!this.role.business.vendorPlan) {
        this.$swal.fire({
          icon: "error",
          title: "Ahidi Plan not found",
          text: "An Ahidi Plan is required!",
        });
        return;
      }

      // const total_amount = this.grandTotal - this.discountAmount;
      const orderDetails = {
        notes: "Pay by Card - Paystack",
        payment: {
          method: "card", //card|m-pesa|cash|paylater
          source: "paystack",
          // amount: 0, //this.payableAmount, this will be set on the paystack callback
          payOnlyDeposit: this.payOnlyDeposit,
          currency: "KES",
        },
      };
      this.isLoading = true;
      this.loadingMessage = "Processing order.....";
      this.saveOrder({ ...orderDetails })
        .then(async (result) => {
          if (result.order) {
            // this.paystack.amount = order.payment.amount * 100;
            // this.paystack.total = this.grandTotal;
            // this.paystack.orderId = order._id;
            // const url = await this.initTransaction({
            //   amount: this.payableAmount * 100,
            //   reference: order._id,
            //   email: order.client.email,
            //   subaccount: order.business.paystackSubaccountCode,
            // });

            this.authUrl = result.checkoutUrl;
            this.paystackDialog = true;
            //this.isLoading = false;
            this.processOrderPayment(result.order._id);
          }
        })
        .catch((e) => {
          this.$swal.fire({
            icon: "error",
            title: "Card Payment Error",
            text: (e as Error).message || "An Error Occurred!",
          });
        });
    },
    setAddress() {
      let loc = this.locationPage.docs.find((item) => item.isDefault);

      if (!loc) loc = this.locationPage.docs[0];

      if (loc) {
        const names = loc.name.split(", ");
        const country = names[names.length - 1];
        const city = names[names.length - 2];
        this.addressChoice = {
          country,
          city,
          addressline1: "n/a",
          phone: "n/a",
        };
      }
    },
    async payByServicePackage() {
      if (!this.servicePackageCode) {
        this.$swal.fire({
          icon: "error",
          title: "Package Code Required",
          text: "Please provide the service package code for the client",
        });
        return;
      }
      //const { total_amount } = this.cash;
      const orderDetails = {
        notes: "Pay by Service Package",
        payment: {
          method: "service-package", //card|m-pesa|cash|paylater|service-package
          source: this.servicePackageCode,
          // amount: this.payableAmount,
          payOnlyDeposit: this.payOnlyDeposit,
          currency: "KES",
        },
      };
      this.saveOrder({ ...orderDetails })
        .then(({ order }) => {
          if (order?._id) {
            this.isLoading = true;
            this.loadingMessage = "Processing order.....";
            const _order = order as Order;
            this.processOrderPayment(_order._id);
          }
        })
        .catch((e) => {
          this.$swal.fire({
            icon: "error",
            title: "Payment Error",
            text: (e as Error).message || "An Error Occurred!",
          });
        });
    },
    async checkRoomAvailability(serviceRequests) {
      try {
        const availability = await this.$store.dispatch(
          "cart/fetchRoomAvailability",
          serviceRequests
        );
        console.log("Room availability:", availability);
        // Handle the availability data as needed
      } catch (error) {
        console.error("Failed to fetch room availability:", error);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ORDER_CHECKOUT")) {
      this.$store.registerModule("ORDER_CHECKOUT", OrderStoreModule);
    }

    if (!this.$store.hasModule("ORDER_PAYMENTS")) {
      this.$store.registerModule("ORDER_PAYMENTS", PaymentStoreModule);
    }
    if (!this.$store.hasModule("_Membership")) {
      this.$store.registerModule("_Membership", MembershipModule);
    }

    if (!this.$store.hasModule("_Client")) {
      this.$store.registerModule("_Client", ClientModule);
    }

    // if (!this.$store.hasModule("PAYSTACK_")) {
    //   this.$store.registerModule("PAYSTACK_", paystackStoreModule);
    // }
    if (!this.$store.hasModule("LOCATION_LIST")) {
      this.$store.registerModule("LOCATION_LIST", locationStoreModule);
    }

    if (!this.$store.hasModule("CHECK_VOUCHER")) {
      this.$store.registerModule("CHECK_VOUCHER", voucherStoreModule);
    }
    if (!this.$store.hasModule("EMPLOYEE")) {
      this.$store.registerModule("EMPLOYEE", employeeStoreModule);
    }
  },
  beforeDestroy() {
    clearTimeout(this.pollTimeout);
    clearInterval(this.pollInterval);
    this.$store.unregisterModule("ORDER_CHECKOUT");
    this.$store.unregisterModule("ORDER_PAYMENTS");
    this.$store.unregisterModule("PAYMENT_GATEWAY");
    this.$store.unregisterModule("_Membership");
    this.$store.unregisterModule("_Client");
    this.$store.unregisterModule("PAYSTACK_");
    this.$store.unregisterModule("LOCATION_LIST");
    this.$store.unregisterModule("CHECK_VOUCHER");
  },
});
